<template>
  <div class="team">
    <div class="columns" style="margin-left: unset; margin-right: unset;">
      <div class="tabs">
        <div
          class="tabs__navigation tabs__navigation--gallery column col-12 col-sm-12 col-md-10"
        >
          <router-link
            class="tabs__navigation-item"
            to="/about/advisory-board/andreas-straarup"
            >Andreas Straarup</router-link
          >
          <router-link
            class="tabs__navigation-item"
            to="/about/advisory-board/brigitte-schnakenbourg"
            >Brigitte Schnakenbourg</router-link
          > 
          <router-link
            class="tabs__navigation-item"
            to="/about/advisory-board/outi-sortino"
            >Outi Sortino</router-link
          >
          <router-link
            class="tabs__navigation-item"
            to="/about/advisory-board/anna-mindeloef"
            >Anna Mindelöf</router-link
          > 
        </div>

        <AdvisoryBoardGallery v-bind:passedActiveMember="1" />

        <div class="tabs__content">
          <div class="tabs__content-item">
            <div class="column col-12">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdvisoryBoardGallery from "@/components/AdvisoryBoardGallery.vue";

export default {
  name: "AdvisoryBoard",
  components: {
    AdvisoryBoardGallery,
  },
};
</script>

<style lang="scss">
.tabs__content-item {
  @media (min-width: $size-md + 1px) {
    padding-top: 1rem;
  }
}

.column--full-width-bg {
  @include column--full-width-bg($color-white);
}

.list--boxed {
  @include list--boxed;

  li {
    p {
      margin: 0;
      text-align: center;
      width: 100%;
    }
  }
}

.list--boxed-left-bottom {
  @include list--boxed-left-bottom;
}

.list--dash {
  @include list--dash;

  &.list__item {
    align-items: flex-start;
    flex: 100%;
    text-align: left;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .list__item {
    flex: 0 1 100%;
  }
}

.list--dash-columns {
  .list__item {
    @media (min-width: $size-md) {
      flex: 0 1 45%;
    }
  }
}

.list--tagged {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9rem;
  letter-spacing: 0.82px;

  .list__item {
    background-color: $color-primary-50;
    padding: 0.7em 1.5em;
    margin-right: 0.65em;
    margin-top: 0.65em;
  }
}

.list--contact {
  .list__item {
    &:first-child {
      margin-top: 1.7em;
    }
  }

  a {
    display: block;

    &[href*="linkedin"],
    &[href*="xing"] {
      &::before {
        $size: 1.4rem;

        content: "";
        width: $size;
        height: $size;
        display: inline-block;
        background-size: contain;
        margin-right: 1em;
        background: url("../../../assets/images/icon-linkedin-green.svg")
          no-repeat center center;
        vertical-align: text-bottom;
      }
    }

    &[href*="xing"] {
      &::before {
        background-image: url("../../../assets/images/icon-xing-green.svg");
      }
    }

    &[href^="tel"],
    &[href^="mailto"] {
      color: $color-primary-700;

      &:hover {
        color: $color-primary;
      }
    }
  }
}

.team {
  &__member-headline {
    margin-top: 3.5rem;
    margin-bottom: 0.2rem;

    + p {
      margin-bottom: 2.3rem;
    }
  }
}

.columns--quote {
  align-items: flex-end;
  margin: 10vw 0;

  .column {
    padding-right: 0;
  }
}
</style>
